import styled from 'styled-components'
import { Card } from '@noahswap/uikit'

export const BodyWrapper = styled(Card)<{ noMaxWidth?: boolean }>`
  border-radius: 24px;
  max-width: ${({ noMaxWidth }) => (noMaxWidth ? 'none' : '440px')};
  width: 100%;
  z-index: 1;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, noMaxWidth }: { children: React.ReactNode; noMaxWidth?: boolean }) {
  return <BodyWrapper noMaxWidth={noMaxWidth}>{children}</BodyWrapper>
}
