/* eslint-disable no-param-reassign */
/* eslint-disable */
import { isTradeBetter } from 'utils/trades'
import { Currency, CurrencyAmount, Pair, Token, Trade, TradeType } from '@noahswap/sdk'
import flatMap from 'lodash/flatMap'
import { useEffect, useMemo, useState } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { WrappedTokenInfo, TagInfo, TokenAddressMap, EMPTY_LIST } from '@noahswap/tokens'
import { useUserSingleHopOnly } from 'state/user/hooks'
import {
  BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  ADDITIONAL_BASES,
} from 'config/constants/exchange'
import { PairState, usePairs } from './usePairs'
import { wrappedCurrency } from '../utils/wrappedCurrency'

import { useAllTokens, useUnsupportedTokens, useWarningTokens } from './Tokens'
// import { useCommonTokens } from './useCommonTokens'
import useUserAddedTokens, { userAddedTokenSelector } from '../state/user/hooks/useUserAddedTokens'

export function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency, listData?: Currency): Pair[] {
  const { chainId } = useActiveWeb3React()

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]
  // const defaultTokens = useAllTokens()
  // const [allTokens, setAllTokens] = useState<any>({}) // 初始数据设为null或其他默认值
  const userAddedTokens = useUserAddedTokens()
  // const allTokens = useCommonTokens()
  // console.log('最终的得到✌🏻✌🏻✌🏻✌🏻✌🏻✌🏻✌🏻', listData)
  const allTokens = { ...listData, ...userAddedTokens }
  // useEffect(() => {
  //   const data = useCommonTokens()
  //   setAllTokens(data)
  // }, [currencyA, currencyB])
  // const allTokens2 = JSON.parse(localStorage.getItem('listData'))
  // const objGOGO = listToTokenMap(allTokens2)
  // console.log('Trades.ts', '执行了getCoinList')
  // console.log('allTokens', allTokens)

  let coinArr = [] // 这里的coinArr表示两两配对的币种数据, 之前是用getCoinList全部币种做配对
  if (process.env.NODE_ENV === 'development') {
    // EOS、USDT、USDC、DAI (测试服)
    coinArr = [
      '0x6cCC5AD199bF1C64b50f6E7DD530d71402402EB6',
      '0xCE47ff7bEb3ee245A3274dA3db50FC795b2fB1e4',
      '0xb3854c53E7415963E1309520965903Bc52B2bE81',
      '0x837aF628eBb4b98bD581954e50b1A07cEcd721FB',
    ]
  } else {
    // Wrapped、Tether USD (EOS)、NOAH、Tether USD、Dai Stablecoin、USD Coin
    coinArr = [
      '0xc00592aA41D32D137dC480d9f6d0Df19b860104F',
      '0x2a3b2d64960036de519dC4a45CAfd532Bfa99Ff0',
      '0x33B57dC70014FD7AA6e1ed3080eeD2B619632B8e',
      '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
      '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
      '0x765277EebeCA2e31912C9946eAe1021199B39C61',
    ]
  }
  // console.log(process.env.NODE_ENV)
  // EOS、USDT、USDC、DAI (测试服)
  // const coinArr = [
  //   '0x6cCC5AD199bF1C64b50f6E7DD530d71402402EB6',
  //   '0xCE47ff7bEb3ee245A3274dA3db50FC795b2fB1e4',
  //   '0xb3854c53E7415963E1309520965903Bc52B2bE81',
  //   '0x837aF628eBb4b98bD581954e50b1A07cEcd721FB',
  // ]
  const filteredObj = coinArr.reduce((acc, coinAddress) => {
    if (allTokens[coinAddress]) {
      acc[coinAddress] = allTokens[coinAddress]
    }
    return acc
  }, {})

  // console.log('filteredObjfilteredObjfilteredObj', filteredObj);

  const aaa = Object.keys(filteredObj).map((i) => {
    return filteredObj[i]
  })
  // console.log('aaa', aaa)
  const bases: Token[] = useMemo(() => {
    if (!chainId) return []

    const common = aaa ?? []
    const additionalA = tokenA ? ADDITIONAL_BASES[chainId]?.[tokenA.address] ?? [] : []
    const additionalB = tokenB ? ADDITIONAL_BASES[chainId]?.[tokenB.address] ?? [] : []

    return [...common, ...additionalA, ...additionalB]
    // if(singleHopOnly){
    //   return [...additionalA, ...additionalB]
    // } else {
    //   return [...common, ...additionalA, ...additionalB]
    // }
  }, [chainId, tokenA, tokenB])

  const basePairs: [Token, Token][] = useMemo(
    () => flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])),
    [bases],
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA_, tokenB_]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]

              const customBasesA: Token[] | undefined = customBases?.[tokenA_.address]
              const customBasesB: Token[] | undefined = customBases?.[tokenB_.address]

              if (!customBasesA && !customBasesB) return true

              if (customBasesA && !customBasesA.find((base) => tokenB_.equals(base))) return false
              if (customBasesB && !customBasesB.find((base) => tokenA_.equals(base))) return false

              return true
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId],
  )
  // console.log('allPairCombinations', allPairCombinations)
  const allPairs = usePairs(allPairCombinations)
  // console.log('usePairs', usePairs)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {}),
      ),
    [allPairs],
  )
}

const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
  currencyAmountIn?: CurrencyAmount<Currency>,
  currencyOut?: Currency,
  listData?: any,
): Trade<Currency, Currency, TradeType> | null {
  // console.log('useTradeExactIn', '触发useAllCommonPairs')
  // console.log('👿👿👿👿👿👿👿', listData)
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut, listData)
  // console.log(allowedPairs, 'allowedPairsallowedPairs')

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
          null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade<Currency, Currency, TradeType> | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  }, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount<Currency>,
  listData?: any,
): Trade<Currency, Currency, TradeType> | null {
  // console.log('useTradeExactOut', '触发useAllCommonPairs')
  // console.log('👿👿👿👿👿👿👿', listData)
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency, listData)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
          null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }
    return null
  }, [currencyIn, currencyAmountOut, allowedPairs, singleHopOnly])
}

export function useIsTransactionUnsupported(currencyIn?: Currency, currencyOut?: Currency): boolean {
  const unsupportedTokens: { [address: string]: Token } = useUnsupportedTokens()
  const { chainId } = useActiveWeb3React()

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  // if unsupported list loaded & either token on list, mark as unsupported
  if (unsupportedTokens) {
    if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}

export function useIsTransactionWarning(currencyIn?: Currency, currencyOut?: Currency): boolean {
  const unsupportedTokens: { [address: string]: Token } = useWarningTokens()
  const { chainId } = useActiveWeb3React()

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  // if unsupported list loaded & either token on list, mark as unsupported
  if (unsupportedTokens) {
    if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}
